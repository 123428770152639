<template>
  <div class="bigbox">
    <div class="zhfwtop">
      <div class="indeximgtopcontent">
         <div class="indexlogo"></div>
      <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span >产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <li  @click="go('/cpjjfa/zhcg')">智慧采购</li>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li @click="go('/yhal')"><span class="active2">用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
      </div>

      <div class="zhfwbutton"></div>
    </div>
    <!-- content -->
    <div class="zkdbottom">
      <div class="zkdbottomcontent">
        <div class="zkdbottomcontent1">创新创业学院网站平台升级开发项目是专门为高等校院的师生提供人员及项目管理信息服务的产品。针对校园身份的不同，实现了不同身份权限功能的区分，便于人员的使用和人员信息的管理、项目管理、活动管理。同时连通了网站群发布新闻功能、活动功能，使新闻、活动内容更好的展示到门户网站，便于浏览查看。</div>
        <div class="zkdbottomcontenttopimg"></div>
        <div class="zkdbottomtitlte">项目亮点</div>
        <div class="zkdbottomtitlteindece">
          <p>1、支持桌面及移动设备同步应用，与网站、PC客户端进行资源同享、用户同步。</p>
          <p>2、利用移动端地便捷优势，方便学生、导师及管理服务人员信息交互和工作衔接。</p>
          <p>3、主要功能包含高校新生学籍注册、课程在线选修、在线学习、在线学分登记、在线场地申请、设备借用、在线咨询、远程辅导、组队交流、信息发布、课程表等。</p>
        </div>
        <div class="zkd_xmldback"></div>
      </div>
    </div>
    <!--  -->
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../../components/TabarBottom.vue'
  export default {
  components: { TabarBottom },
    data(){
      return{

      }
    },
      methods:{
       go(path){
        this.$router.push({
          path:path
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
//
.zkdbottom{
  width: 100%;
  height: 1540px;
  margin: 0 auto;
  .zkdbottomcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
    padding-top: 60px;
    .zkdbottomcontent1{
      text-indent: 2rem;
      width: 1200px;
      height: 80px;
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #282A2E;
    }
    .zkdbottomcontenttopimg{
      margin-top: 40px;
      width: inherit;
      height: 585.63px;
      background: url('../../.././assets/zkdbottomcontenttopimg.png') no-repeat center;
      background-size: 1200px 585.63px;
    }
    .zkdbottomtitlte{
      margin-top: 40px;
      margin-bottom: 40px;
      text-align: center;
      width: 1200px;
      height: 36px;
      font-size: 30px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #282A2E;
    }
    .zkdbottomtitlteindece{
      p{
        text-indent: 2rem;
        width: 1200px;
        height: 51px;
        font-size: 18px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #282A2E;
        margin-bottom: 40px;
      }
    }
    .zkd_xmldback{
      width: 1200px;
      height: 380px;
      background: url('../../.././assets/zkd_xmldback.png') no-repeat center;
      background-size: 1200px 380px;
    }
  }
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../../.././assets/zkdindexback.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;

  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
